import React, { HTMLAttributes, useEffect } from 'react';
import { css, Theme } from '@emotion/react';
import styled from '@emotion/styled';
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from 'react-share';
import {
  faFacebookF,
  faLinkedinIn,
  faTwitter,
} from '@fortawesome/fontawesome-free-brands';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Grid from '@/components/atoms/Grid';

import { GridProps } from '@/components/atoms/Grid/Grid';

interface Props
  extends Omit<HTMLAttributes<HTMLDivElement>, 'color'>,
    Omit<GridProps, 'children'> {
  variant?: 'row' | 'column';
  shareText?: string;
}

const Icon = styled(FontAwesomeIcon)`
  font-size: 2.4rem;
`;
const mb = (theme: Theme) => css`
  margin-bottom: ${theme.spacing(3)};
`;
const mr = (theme: Theme) => css`
  margin-right: ${theme.spacing(3)};
`;

const Share = ({ variant = 'row', shareText, ...rest }: Props): JSX.Element => {
  const host = process.env.GATSBY_HOST;
  let location = '';

  useEffect(() => {
    location += window.location.pathname;
  }, []);

  const shareUrl = `${host}/${location}`;

  return (
    <Grid container flexDirection={variant} {...rest}>
      <div css={variant === 'row' ? mr : mb}>
        <FacebookShareButton url={shareUrl} quote={shareText} hashtag="Guilmin">
          {/* @ts-ignore */}
          <Icon icon={faFacebookF} />
        </FacebookShareButton>
      </div>
      <div css={variant === 'row' ? mr : mb}>
        <TwitterShareButton
          title={shareText}
          hashtags={[]}
          related={['@Guilmin']}
          url={shareUrl}
        >
          {/* @ts-ignore */}
          <Icon icon={faTwitter} />
        </TwitterShareButton>
      </div>
      <div>
        <LinkedinShareButton
          title={shareText}
          summary="Description de la page"
          url={shareUrl}
          source="Guilmin"
        >
          {/* @ts-ignore */}
          <Icon icon={faLinkedinIn} />
        </LinkedinShareButton>
      </div>
    </Grid>
  );
};

export default Share;
