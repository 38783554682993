import React from 'react';
import { graphql } from 'gatsby';

import Layout from '@/components/organisms/Layout';
import Seo from '@/components/atoms/Seo/Seo';
import ArticleContainer from '@/containers/Article/ArticleContainer';

import { ArticlePageQuery } from '../../graphql-types';

interface Props {
  data: ArticlePageQuery;
}

export const query = graphql`
  query ArticlePage($id: String!) {
    prismicArticlePage(id: { eq: $id }) {
      data {
        meta_title
        meta_description
        ...ArticleHero
        ...ArticleContent
      }
    }
    prismicCompanyStats {
      data {
        ...CompanyStats
      }
    }
    prismicHomePageBodyContactbox {
      primary {
        image {
          url
          alt
        }
      }
    }
  }
`;

const ArticleTemplate = ({ data }: Props): JSX.Element => {
  return (
    <Layout invertNav type="magasin">
      <Seo title="Article" description="Article" />
      <ArticleContainer data={data} />
    </Layout>
  );
};

export default ArticleTemplate;
