import React from 'react';
import styled from '@emotion/styled';
import { graphql } from 'gatsby';

import Background from '@/components/atoms/Background';
import Typography from '@/components/atoms/Typography';
import Container from '@/components/atoms/Container';
import Tag from '@/components/atoms/Tag';

import mq from '@/styles/mq';

import ImageDataType from '@/types/data/image';

interface Props {
  title?: React.ReactNode;
  background?: ImageDataType;
}

const ArticleHeroRoot = styled(Container)`
  padding-top: ${({ theme }) => theme.spacing(5)};
  display: flex;
  flex-direction: column;
  align-items: center;

  ${mq('lg')} {
    padding-top: ${({ theme }) => theme.spacing(15)};
  }
`;
const HeroBackground = styled(Background)`
  width: 100%;
  height: 600px;
  overflow: visible;
  margin-top: ${({ theme }) => theme.spacing(12)};
  min-width: 1240px;

  & img,
  &:after {
    border-radius: 10px;
  }
`;
const Title = styled(Typography)`
  position: absolute;
  top: 0;
  transform: translateY(-50%);
  text-align: center;
  width: 100%;

  & .text-white {
    display: block;
    color: ${({ theme }) => theme.color.white.main};
  }
`;

const ArticleHero = ({ title, background }: Props): JSX.Element => {
  return (
    <ArticleHeroRoot maxWidth="lg">
      <Tag label="Tendances" />
      <HeroBackground background={background}>
        <Title variant="h1">{title}</Title>
      </HeroBackground>
    </ArticleHeroRoot>
  );
};

export const query = graphql`
  fragment ArticleHero on PrismicArticlePageDataType {
    title {
      raw
    }
    hero_background {
      url
      alt
    }
  }
`;

export default ArticleHero;
