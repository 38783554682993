import React from 'react';
import { RichText } from 'prismic-reactjs';

import ArticleHero from '@/containers/Article/ArticleHero';
import ArticleContent from '@/containers/Article/ArticleContent';
import ArticleContactBox from '@/containers/Article/ArticleContactBox';
import ArticleStats from '@/containers/Article/ArticleStats';

import {
  ArticlePageQuery,
  PrismicCompanyStatsStatsGroupType,
} from '../../../graphql-types';

//@ts-ignore
import Contactboximg from '../../../static/contactbox.jpeg';

interface Props {
  data: ArticlePageQuery;
}

const ArticleContainer = ({ data }: Props): JSX.Element => {
  const { title, hero_background, content, author, publication_date } =
    data?.prismicArticlePage?.data || {};

  const articleAuthor = author?.document?.data?.name;

  const { primary } = data?.prismicHomePageBodyContactbox || {};

  const HeroProps = {
    title: <RichText render={title?.raw} />,
    background: {
      url: hero_background?.url,
      alt: hero_background?.alt,
    },
  };

  const ContentProps = {
    content: <RichText render={content?.raw} />,
    author: articleAuthor,
    publicationDate: publication_date,
  };

  // const {
  //   contact_box_background,
  //   contact_box_section_title,
  //   contact_box_content,
  // } = data?.prismicContactBox?.data || {};

  const { stats } = data?.prismicCompanyStats?.data || {};

  // const ContactBoxProps = {
  //   background: {
  //     url: contact_box_background?.url,
  //     alt: contact_box_background?.alt,
  //   },
  //   title: <RichText render={contact_box_section_title?.raw} />,
  //   content: <RichText render={contact_box_content?.raw} />,
  // };

  const ContactBoxProps = {
    background: {
      url: primary?.image?.url,
      alt: primary?.image?.alt,
    },
    title: 'Vous souhaitez plus d’informations ?',
    content:
      'Nos équipes en magasin sont à votre disponibilité pour tout type de questions. Vous pouvez également remplir notre formulaire de contact.',
    button: {
      text: 'Nous contacter',
      link: '/magasin/contact',
    },
  };

  const StatsProps = {
    stats: stats?.map((el: PrismicCompanyStatsStatsGroupType | null) => ({
      number: <RichText render={el?.number?.raw} />,
      label: el?.label,
    })),
  };

  return (
    <>
      <ArticleHero {...HeroProps} />
      <ArticleContent {...ContentProps} />
      <ArticleContactBox {...ContactBoxProps} />
      <ArticleStats {...StatsProps} />
    </>
  );
};

export default ArticleContainer;
