import React from 'react';
import { graphql } from 'gatsby';
import styled from '@emotion/styled';
import { DateTime } from 'luxon';

import Container from '@/components/atoms/Container';
import Grid from '@/components/atoms/Grid';
import Share from '@/components/molecules/Share';
import Typography from '@/components/atoms/Typography';

import mq from '@/styles/mq';

interface Props {
  content?: React.ReactNode;
  author?: string | null;
  publicationDate?: string | null;
}

const ArticleContentRoot = styled(Container)`
  margin-top: ${({ theme }) => theme.spacing(5)};

  ${mq('md')} {
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
  }

  ${mq('lg')} {
    max-width: ${({ theme }) => theme.breakpoints.lg}px;
    margin-top: ${({ theme }) => theme.spacing(10)};
  }

  & .text-secondary {
    color: ${({ theme }) => theme.color.textSecondary.main};
  }

  & h2 {
    font-size: 2.4rem;

    ${mq('lg')} {
      font-size: 3.6rem;
    }
  }

  & h3 {
    font-size: 1.8rem;

    ${mq('lg')} {
      font-size: 2.4rem;
    }
  }

  & p {
    font-size: 1.6rem;
    font-weight: 500;
  }

  & img {
    width: 100%;
    height: 500px;
    object-fit: contain;
  }
`;
const LeftGrid = styled(Grid)``;
const RightGrid = styled(Grid)`
  display: flex;
  flex-direction: column;

  ${mq('lg')} {
    align-items: flex-end;
  }
`;
const ShareTitle = styled(Typography)`
  margin-top: ${({ theme }) => theme.spacing(5)};
  margin-bottom: ${({ theme }) => theme.spacing(2)};
  text-align: center;

  ${mq('lg')} {
    text-align: right;
    margin-top: ${({ theme }) => theme.spacing(1)};
  }
`;
const ShareDesktop = styled(Share)`
  display: none;

  ${mq('lg')} {
    display: flex;
  }
`;
const ShareMobile = styled(Share)`
  display: flex;

  ${mq('lg')} {
    display: none;
  }
`;
const Author = styled(Typography)`
  font-weight: 900;
  font-size: 2rem;
  margin-top: ${({ theme }) => theme.spacing(5)};
`;

const ArticleContent = ({
  content,
  author,
  publicationDate,
}: Props): JSX.Element => {
  const publishDateFormatted = publicationDate
    ? DateTime.fromISO(publicationDate)
        .setLocale('fr')
        .toLocaleString(DateTime.DATE_FULL)
    : '';

  return (
    <ArticleContentRoot>
      <Grid container>
        <LeftGrid xxs={12} lg={9}>
          <div>{content}</div>
          {publishDateFormatted && author && (
            <Author>
              Le {publishDateFormatted} par {author}
            </Author>
          )}
        </LeftGrid>
        <RightGrid xxs={12} lg={3}>
          <ShareTitle>SHARE</ShareTitle>
          <ShareDesktop variant="column" alignItems="flex-end" />
          <ShareMobile
            variant="row"
            justifyContent="center"
            alignItems="center"
          />
        </RightGrid>
      </Grid>
    </ArticleContentRoot>
  );
};

export const query = graphql`
  fragment ArticleContent on PrismicArticlePageDataType {
    publication_date
    author {
      document {
        ... on PrismicAuthor {
          id
          data {
            name
          }
        }
      }
    }
    content {
      raw
    }
  }
`;

export default ArticleContent;
